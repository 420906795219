import React from "react";

const ArrowBtn = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      className="group-hover:rotate-45 group-hover:scale-125 transition-all"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.9105" cy="27.9281" r="27.3832" fill="black" />
      <path
        d="M22.3984 30.822C22.0078 31.2125 22.0078 31.8457 22.3984 32.2362C22.7889 32.6267 23.422 32.6267 23.8126 32.2362L22.3984 30.822ZM33.7184 21.9163C33.7184 21.364 33.2706 20.9163 32.7184 20.9163L23.7184 20.9162C23.1661 20.9162 22.7184 21.364 22.7184 21.9162C22.7184 22.4685 23.1661 22.9162 23.7184 22.9162L31.7184 22.9163L31.7183 30.9163C31.7183 31.4685 32.166 31.9163 32.7183 31.9163C33.2706 31.9163 33.7183 31.4685 33.7183 30.9163L33.7184 21.9163ZM23.8126 32.2362L33.4255 22.6234L32.0113 21.2092L22.3984 30.822L23.8126 32.2362Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowBtn;
