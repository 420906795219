import React from "react";

const Plus = () => {
  return (
    <svg
      viewBox="0 0 47 47"
      className="h-8 w-8 md:h-9 md:w-9 lg:h-12 lg:w-12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.617188"
        y="0.0673828"
        width="46.1063"
        height="46.1063"
        rx="23.0532"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6297 16.3965H22.7086V22.1598H16.9453V24.0809H22.7086V29.8442H24.6297V24.0809H30.393V22.1598H24.6297V16.3965Z"
        fill="black"
      />
    </svg>
  );
};

export default Plus;
