import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import BookCounseltation from "./Pages/BookCounseltation";
import BookCounseltationForm from "./Pages/BookCounseltationForm";

function App() {
  return (
    <div className="bg-light-background    w-full ">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/book-consultation" element={<BookCounseltation />} />
        <Route
          path="/book-consultation-form"
          element={<BookCounseltationForm />}
        />
      </Routes>
    </div>
  );
}

export default App;
