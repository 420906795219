import React from "react";

const Close = () => {
  return (
    <svg
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-8 w-8 md:h-9 md:w-9 lg:h-12 lg:w-12"
    >
      <rect
        x="0.617188"
        y="0.16748"
        width="46.1063"
        height="46.1063"
        rx="23.0532"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1056 19.1456L27.7471 17.7872L23.6719 21.8624L19.5966 17.7872L18.2382 19.1456L22.3135 23.2209L18.2382 27.2961L19.5966 28.6545L23.6719 24.5793L27.7471 28.6545L29.1056 27.2961L25.0303 23.2209L29.1056 19.1456Z"
        fill="white"
      />
    </svg>
  );
};

export default Close;
